import * as React from 'react';
import { useState } from 'react';
import { formatDate, removeSlash } from '../lib/functions';
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

// components
import CategoryCard from '../components/categorycard';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ThemeSidebar from '../components/theme-immo-sidebar';
import Icon from '../components/icon';
import RelatedArticleCard from '../components/related-article-card';
import LinkText from '../components/link';
import { Article as ArticleComponent } from '@monemprunt/design-system';
import { Link as LinkComponent } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// types & interfaces
import type {
    Article,
    Author,
    Category,
    RelatedArticle,
    TemplatePageProps
} from '../lib/types';
import CtaImage from '../components/cta-image';

type ArticlePageContext = {
    id: number;
    theme: string;
    category: string;
    relatedCategories: Category[];
    relatedArticles: Array<RelatedArticle>;
};

export interface ArticlePageData {
    article: Article & { childHtmlRehype: { body: string } };
    site: {
        siteMetadata: {
            siteURL: string;
        };
    };
}

const ArticlePage: React.FC<
    TemplatePageProps<ArticlePageData, ArticlePageContext>
> = ({
    pageContext: {
        theme: themeSlug,
        category: subjectSlug,
        relatedCategories,
        relatedArticles
    },
    data: {
        article,
        site: {
            siteMetadata: { siteURL }
        }
    },
    location: { pathname }
}) => {
    const [sideBarOpen, setSideBarOpen] = useState(false);

    return (
        <>
            <Seo
                {...article.SEO}
                image={article.cover?.localFile.publicURL}
                pathname={pathname}
            />
            <Layout active="pages/guide-immo">
                <main className="mt-25 grid grid-cols-10 xl:grid-cols-12">
                    {/* SideBar */}
                    <div
                        className={`col-span-2 h-full pt-24 bg-light-50 pb-52  ${
                            sideBarOpen && 'col-span-10 '
                        } sm:pb-64 lg:col-span-3 xl:col-span-3
        `}>
                        <ThemeSidebar
                            className={`w-full ${
                                !sideBarOpen && 'sticky top-40'
                            } lg:sticky lg:top-40`}
                            open={sideBarOpen}
                            onClose={() => setSideBarOpen(false)}
                            onThemeClicked={() => {
                                setSideBarOpen(true);
                                scroll({
                                    top:
                                        document.querySelector('main')
                                            .offsetTop - 80,
                                    behavior: 'smooth'
                                });
                            }}
                            activeThemeSlug={themeSlug}
                            selectedSubjectSlug={subjectSlug}
                        />
                    </div>

                    {/* Article */}
                    <div
                        className={`mt-15 col-span-8 space-y-15 px-5 lg:block lg:col-span-7 xl:col-span-9 lg:px-15 ${
                            sideBarOpen && 'hidden'
                        }`}>
                        <Title title={article.title} />

                        <ArticleComponent
                            customLink={(props) => <LinkComponent {...props} />}
                            content={article.body}
                            callToAction={() => (
                                <div className={'flex justify-center'}>
                                    <CtaImage />
                                </div>
                            )}
                        />

                        <Writer
                            author={article.author}
                            published_date={article.published_date}
                        />

                        <ShareButtons
                            title={article.title}
                            siteURL={siteURL}
                            pathname={pathname}
                        />

                        <RelatedArticles articles={relatedArticles} />

                        <RelatedCategories
                            categories={relatedCategories}
                            themeSlug={themeSlug}
                        />
                    </div>
                </main>
            </Layout>
        </>
    );
};

const Title: React.FC<{ title: string }> = ({ title }) => (
    <header className={'space-y-8'}>
        <h1 className="font-bold text-2xl lg:text-3xl">{title}</h1>
    </header>
);

const Writer: React.FC<{ author: Author; published_date: string }> = ({
    author,
    published_date
}) => {
    const authorImage = getImage(author.image?.localFile);
    // Get Date to cleaner format
    const date = formatDate({ date: published_date });
    return (
        <>
            <div className={'flex justify-between md:items-center'}>
                <div className={'flex space-x-4 items-center'}>
                    <GatsbyImage
                        image={authorImage}
                        alt={`Photo de ${author.name}`}
                        className="rounded-full !object-center !object-cover shadow-md"
                    />
                    <div className={'flex flex-col'}>
                        <small className="capitalize text-dark-50">
                            écrit par
                        </small>
                        <span>{author.name}</span>
                    </div>
                </div>
                <small className={'text-dark-50 text-right'}>Le {date}</small>
            </div>
            {author.bio && (
                <div>
                    <p className={'text-sm'}>
                        <b>Bio:</b> {author.bio}
                    </p>
                </div>
            )}
        </>
    );
};

const ShareButtons: React.FC<{
    title: string;
    siteURL: string;
    pathname: string;
}> = ({ title, pathname, siteURL }) => {
    const fullPath = `${removeSlash(siteURL)}${pathname}`;
    const shareText = `Veuillez lire ce superbe article de monmemprunt.com sur le sujet « ${title} » ${fullPath} `;
    return (
        <div className={'space-y-4'}>
            <div className={'font-bold text-lg'}>Partager</div>
            <div className={'flex justify-between space-x-4'}>
                {/*Share with linkedin & facebook*/}
                <div className={'flex space-x-4'}>
                    <a
                        target={'_blank'}
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${fullPath}`}
                        className={
                            'rounded-md shadow-md p-8 flex items-center content-center bg-secondary-100'
                        }
                        aria-label={'Partager sur linkedin'}>
                        <Icon icon={'linkedin'} className={'text-white h-5'} />
                    </a>
                    <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${fullPath}`}
                        target={'_blank'}
                        className={
                            'rounded-md shadow-md p-8 flex items-center content-center'
                        }
                        aria-label={'Partager sur facebook'}>
                        <Icon
                            icon={'facebook'}
                            className={'text-secondary-100 h-5'}
                        />
                    </a>
                </div>

                {/*Share with twitter*/}
                <a
                    target={'_blank'}
                    href={`https://twitter.com/intent/tweet?original_referer=${encodeURI(
                        siteURL
                    )}&ref_src=${fullPath}&text=${encodeURI(shareText)}`}
                    className={
                        'rounded-md shadow-md p-8 flex space-x-4 items-center lg:p-4'
                    }>
                    <Icon
                        icon={'twitter'}
                        className={'h-5 text-secondary-100 lg:hidden'}
                    />

                    <span className={'text-secondary-100 hidden lg:inline'}>
                        Monemprunt
                    </span>
                    <span className={'text-dark-50 hidden lg:inline'}>
                        « {title} »
                    </span>
                    <span
                        className={
                            'bg-secondary-100 hidden lg:flex space-x-4 text-white p-4 rounded-md items-center font-bold'
                        }>
                        <Icon icon={'twitter'} className={'h-5'} />
                        <span>Twitter</span>
                    </span>
                </a>
            </div>
        </div>
    );
};

const RelatedArticles: React.FC<{
    articles: Array<RelatedArticle>;
}> = ({ articles }) =>
    articles.length > 0 && (
        <section className={'space-y-4'}>
            <h2 className="font-bold text-2xl">
                Ces articles pourraient vous intéresser :
            </h2>
            <ul className={'grid gap-6 md:grid-cols-2 lg:grid-cols-3'}>
                {articles.map((article) => (
                    <li key={article.id}>
                        <RelatedArticleCard article={article} />
                    </li>
                ))}
            </ul>
        </section>
    );

const RelatedCategories: React.FC<{
    categories: Array<Category>;
    themeSlug: string;
}> = ({ categories, themeSlug }) => {
    return (
        <section
            className={'space-y-8 pb-10 md:pb-60 lg:px-15 lg:pb-80 xl:pb-60'}>
            {categories.length > 0 && (
                <>
                    <h2 className="font-bold text-2xl flex justify-between">
                        <span>Dans la même catégorie :</span>
                        <LinkText
                            active
                            href={`/pages/guide-immo/${themeSlug}`}
                            label={'Toutes les catégories'}
                            className={'text-xl hidden lg:inline'}
                        />
                    </h2>
                    <ul
                        className={
                            'gap-6 pb-40 md:pb-0 grid lg:grid-cols-2 xl:grid-cols-3'
                        }>
                        {categories.map((category) => (
                            <li key={category.id}>
                                <CategoryCard
                                    // @ts-ignore
                                    category={category}
                                    basePath={`/pages/guide-immo/${themeSlug}`}
                                />
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </section>
    );
};

export default ArticlePage;

export const query = graphql`
    query SingleArticle($id: Int) {
        site {
            siteMetadata {
                siteURL
            }
        }
        article: strapiArticle(strapiId: { eq: $id }) {
            id: strapiId
            title
            body

            published_date
            author {
                name
                bio
                image {
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                                width: 65
                            )
                        }
                    }
                }
            }

            SEO {
                title
                slug
                description
            }

            cover {
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                            width: 1500
                        )
                    }
                }
            }

            relatedArticles: related_articles {
                id
                title
                summary
                cover {
                    localFile {
                        publicURL
                        relativePath
                    }
                }
                published_date
                SEO {
                    slug
                }
            }
        }
    }
`;
